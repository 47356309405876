import { EDefectsTableType } from 'features/defects/types/TDefectsPagination'
import { ECaseStageQuery } from 'types/ICase'
import { TCasesManagementTabs } from 'types/TTab'

import {
  EAllCasesTabType,
  ECaseTableType,
  EDistributionTabType,
  EFavoriteList,
  EMyCasesTabType,
} from './ECaseTableType'

/** Сортировка */
export enum ECaseSortBy {
  DIAGNOSTIC_PROCEDURE_TYPE = 'DIAGNOSTIC_PROCEDURE_TYPE',
  INCOMING_ICD_10 = 'INCOMING_ICD_10',
  CASE_DATE = 'CASE_DATE',
  STAGE = 'STAGE',
}

/** Направление сортировки */
export enum ESortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ESlidesCount {
  /** 0 слайдов */
  ZERO = 'ZERO',
  /** > 1 */
  GTE_ONE = 'GTE_ONE',
  /** <= 80% */
  LT_PCT80 = 'LT_PCT80',
  /** >= 80% */
  GTE_PCT80 = 'GTE_PCT80',
  /** 100% */
  PCT100 = 'PCT100',
}

export type TCaseManagementQueryParams = {
  tab: TCasesManagementTabs
  favoritesList?: EFavoriteList
  sortBy: ECaseSortBy
  sortDir: ESortDir
  slidesCount?: ESlidesCount
  stage?: string
  cursor?: string
}

/** Квери параметры менеджмента без таба */
export type TOmitCaseManagementQueryParams = Omit<TCaseManagementQueryParams, 'tab'>

/** Фильтры */
export type TCaseManagementFilterParams = {
  /** Тип исследования */
  caseResearchType?: number[]
  /** По количеству слайдов */
  slidesCount?: ESlidesCount
  /** По статусу */
  stage?: ECaseStageQuery[]
  /** Срочность */
  urgent?: boolean
  /** Способ получения материала */
  diagnosticProcedureTypeId?: number[]
  /** По дате ОТ */
  caseDateFrom?: string
  /** По дате ДО */
  caseDateTo?: string
  /** По распрделенному врачу */
  assignedDoctorUserId?: number[]
  /** Общий поиск */
  query?: string
}

/** Параметры запросов для каждого таба */
export type TQueryParams = {
  [key in ECaseTableType]?: {
    [key in EMyCasesTabType | EDistributionTabType | EAllCasesTabType | EDefectsTableType]?: {
      queryParams: TOmitCaseManagementQueryParams
    }
  }
}

import styled from 'styled-components/macro'

export const SlideContainer = styled.div`
  margin-bottom: 12px;
`

export const Dot = styled.span`
  &:before {
    content: '●';
  }
`

export const SlideText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: var(--color-white);
  transition: background-color 0.3s;
  padding: 4px 8px;

  &:hover {
    background-color: var(--color-bg-3);
  }
`

export const SlideTextInner = styled.div`
  display: flex;
  column-gap: 8px;
`

export const SlideTextDropdown = styled(SlideText)`
  cursor: pointer;
`

export const ButtonAdd = styled.button`
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--color-bg-3);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
`

export const DropDown = styled.div`
  position: absolute;
  color: var(--color-white);
  background-color: var(--color-modal);
  border-radius: 5px;
  z-index: 2;
  padding: 10px;
  width: 100%;
`

import { TableProps } from 'antd'
import { getQueryParams, setSortOrder } from 'features/cases-management/lib/helpers'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { CaseName } from 'features/cases-management/ui/cases-table/CaseName'
import { DateItem, ROW_HEIGHT } from 'features/cases-management/ui/cases-table/CasesTable'
import { useDefectsTableQuery } from 'features/defects/api/query'
import defectsService from 'features/defects/api/service'
import { EDefectsSortBy, EDefectsTableType, SlideWithDefect } from 'features/defects/types/TDefectsPagination'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { AvatarElement, RouterLink, TextElement, TooltipElement } from 'shared/ui/kit'
import { Column } from 'shared/ui/table'
import { convertToUpperCaseWithUnderscore } from 'shared/ui/table/lib/helpers'
import { useCustomVt, useTableSort } from 'shared/ui/table/lib/hooks'
import { StyledCasesTable, WrapperResTable } from 'shared/ui/table/ui/Table.styled'
import styled from 'styled-components'
import { TCasesManagementTabs } from 'types/TTab'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

type Props = {
  tabType: TCasesManagementTabs
}

const DEFAULT_PAGE_SIZE = 20
const SCROLL_Y = '100%'
/** Дефолтное поле для сортировки */
export const DEFAULT_SORT_FIELD = 'defectDate'
/** Дефолтное направление для сортировки */
export const DEFAULT_SORT_DIRECTION = 'ascend'
/** Оффсет количества элементов, где нужно начинать подгрузку следующей страницы */
const NEXT_PAGE_DOWNLOAD_OFFSET = 6
/** Оффсет количества элементов, на которые скролится предыдущая страница после загрузки */
const UP_SCROLL_OFFSET = 3

const DefectsTable: React.FC<Props> = ({ tabType }) => {
  const { t } = useTranslation()
  const workspaceId = useCurrentWorkspaceId()
  const { menuTab } = useCaseManagementRouteParam()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { setTabsQueryParams, tabsQueryParams } = useCaseManagementTabContext()
  const { filterParams } = useCaseManagementContext()

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [cursor, setCursor] = useState<string>()

  const { handleSortChange, sortConfig } = useTableSort<SlideWithDefect>({
    field: DEFAULT_SORT_FIELD,
    order: DEFAULT_SORT_DIRECTION,
  })

  /** Запрос курсора предыдущей страницы (страница может быть от 1 до 20 элементов) */
  const fetchPrevCursor = async (slideId: number) => {
    const { previousPageCursor } = await defectsService.getNextPrevDefectsCursor(Number(workspaceId), slideId)

    return previousPageCursor
  }
  const { defectsList, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetching, isLoading } =
    useDefectsTableQuery(
      tabType as EDefectsTableType,
      DEFAULT_PAGE_SIZE,
      tabsQueryParams[menuTab]?.[tabType]?.queryParams,
      cursor,
      filterParams,
    )

  const getRowClassName = (_: SlideWithDefect, index: number) => (index === selectedRowIndex ? 'selected-row' : '')

  // Обработчик выбора строк
  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: SlideWithDefect[]) => {
    /**
     * Нужно доделать в рамках задачи PFM-8656
     * */
  }

  const rowSelection = {
    onChange: onSelectChange,
  }

  const tableContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const [tableBodyHeight, setTableBodyHeight] = useState(0)

  const onTableUpScroll = async () => {
    const tableOverFlowWrapper = document.querySelector('.ant-table-body') as HTMLElement | null
    if (hasPreviousPage) {
      await fetchPreviousPage()
      tableOverFlowWrapper?.scrollTo({ behavior: 'smooth', top: ROW_HEIGHT * UP_SCROLL_OFFSET })
    }
  }

  const onTableDownScroll = () => {
    hasNextPage && fetchNextPage()
  }

  const onTableScroll: VtOpts['onScroll'] = async ({ isEnd, top }) => {
    if (!isFetching) {
      !top ? onTableUpScroll() : isEnd && onTableDownScroll()
    }
  }

  const [vt] = useCustomVt(
    { onScroll: onTableScroll, scroll: { y: tableBodyHeight - ROW_HEIGHT * NEXT_PAGE_DOWNLOAD_OFFSET } },
    [isFetching, tableBodyHeight],
  )

  useEffect(() => {
    const resizeHandle = () => {
      const tableBody = tableContainerRef.current?.querySelector('.ant-table-wrapper')
      const localTableBodyHeight = tableBody?.clientHeight
      localTableBodyHeight && setTableBodyHeight(localTableBodyHeight)
    }
    /** Рассчитываем количество строк при монтировании компонента */
    resizeHandle()
    /** Добавляем обработчик изменения размера окна для пересчета количества строк */
    window.addEventListener('resize', resizeHandle)
    return () => {
      /** Убираем обработчик при размонтировании компонента  */
      window.removeEventListener('resize', resizeHandle)
    }
  }, [])

  /** Изменение сортировки */
  useEffect(() => {
    setTabsQueryParams((prevState) => ({
      ...prevState,
      [menuTab]: {
        ...prevState[menuTab],
        [tabType]: {
          queryParams: {
            sortBy:
              (sortConfig?.field && convertToUpperCaseWithUnderscore(sortConfig?.field)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortBy,
            sortDir:
              (sortConfig?.order && convertToUpperCaseWithUnderscore(sortConfig?.order)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting).sortDir,
          },
        },
      },
    }))
  }, [sortConfig, menuTab, tabType])

  return (
    <WrapperResTable ref={tableContainerRef}>
      <StyledCasesTable<FC<TableProps<SlideWithDefect>>>
        scroll={{ y: tableBodyHeight - ROW_HEIGHT || SCROLL_Y }}
        components={vt}
        rowSelection={rowSelection}
        rowClassName={getRowClassName}
        pagination={false}
        tableLayout="fixed"
        showSorterTooltip={false}
        dataSource={defectsList}
        onChange={handleSortChange}
        loading={isLoading}
        rowKey={({ slide: { slideId } }, index) => (slideId ? `${slideId}-${index}` : `row-${index}`)}
      >
        <Column
          className="has-divider"
          dataIndex={['barcode', 'caption']}
          key="barcode"
          title={t('Слайд')}
          render={(_: unknown, record: SlideWithDefect) => (
            <RouterLink to={`/`}>
              <EllipsisText>{record.slide.barcode || record.slide.slideMetadata?.commonMetadata?.caption}</EllipsisText>
            </RouterLink>
          )}
        />
        <Column
          className="has-divider"
          dataIndex={['stain', 'name']}
          key="stain"
          title={t('Окраска')}
          render={(stainName: string) => <EllipsisText>{stainName || t('Неизвестно')}</EllipsisText>}
        />
        <Column
          className="has-divider"
          dataIndex="caseId"
          key="caseName"
          title={t('Случай')}
          sorter={true}
          sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, EDefectsSortBy.CASE_ID)}
          render={(_: unknown, record: SlideWithDefect) => {
            const { caseId, caseName, slideStats } = record
            return (
              <CaseName
                isArchive={false}
                key={caseId}
                countDone={slideStats.slidesCount}
                countTotal={slideStats.referencesCount}
                caseName={caseName}
              />
            )
          }}
        />
        <Column
          className="has-divider"
          dataIndex={['defects', 0, 'defectType', 'name']}
          key="defectType"
          title={t('Тип дефекта')}
          render={(defectType: string) => <EllipsisText>{defectType || t('Неизвестно')}</EllipsisText>}
        />
        <Column
          className="has-divider"
          dataIndex={['defects', 0, 'tissuePercentage']}
          key="tissuePercentage"
          title={t('% от ткани')}
          render={(tissuePercentage: number) => <TextElement ellipsis>{tissuePercentage ?? '—'}</TextElement>}
        />
        <Column
          className="has-divider"
          dataIndex={['caseResearchType', 'name']}
          key="caseResearchType"
          title={t('Тип исследования')}
          render={(caseResearchType: string) => <EllipsisText>{caseResearchType || t('Неизвестно')}</EllipsisText>}
        />
        <Column
          className="has-divider"
          dataIndex="createdBy"
          key="createdBy"
          title={t('Кто обнаружил')}
          render={(_: unknown, record: SlideWithDefect) => {
            const isFalsePositive = !!record.defects[0]?.falsePositive
            const fullname = record.defects[0].createdBy?.fullname
            return (
              <div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                <TooltipElement title={isFalsePositive ? 'Ложная детекция' : ''} placement="top">
                  <AvatarElement
                    style={{ border: isFalsePositive ? '1px solid var(--color-red)' : undefined }}
                    fullname={fullname}
                    numberValue={record.defects[0]?.slideDefectId}
                    iconName={!fullname ? (isFalsePositive ? 'aiMistakeSmall' : 'aiSmall') : undefined}
                  />
                </TooltipElement>
                <EllipsisText>{fullname || t('Автоматическая детекция')}</EllipsisText>
              </div>
            )
          }}
        />
        <Column
          className="has-divider"
          dataIndex="defectDate"
          sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, EDefectsSortBy.DEFECT_DATE)}
          sorter={true}
          key="createdAt"
          title={t('Время обнар. дефекта')}
          render={(_: unknown, record: SlideWithDefect) => <DateItem date={record.defects[0].createdAt} />}
        />
      </StyledCasesTable>
    </WrapperResTable>
  )
}

export default DefectsTable

const EllipsisText = styled.span`
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'

import {
  Block,
  Button,
  Dot,
  Item,
  Mark,
  Span,
  Status,
  StyledPanel,
  Title,
  TitleSpan,
  TitleWrapper,
} from './AdditionalOrderInfo.styles'
import { AdditionalOrderInfoProps } from './AdditionalOrderInfo.types'
import { ADDITIONAL_ORDER, STATUSES } from './constants'

export function AdditionalOrderInfo({ data, handleClick, isVisiblePanel }: AdditionalOrderInfoProps) {
  const { t } = useTranslation()

  return (
    <>
      <Button isFocus={isVisiblePanel} onClick={handleClick}>
        {t('Дозаказы')} <Mark>{data.length}</Mark>
      </Button>

      {isVisiblePanel && (
        <StyledPanel>
          {data.map((item) => (
            <Item key={item.researchRequestId}>
              <Block>
                <TitleWrapper>
                  <Title>
                    <TitleSpan>{t(ADDITIONAL_ORDER[item.type])}</TitleSpan>
                    <Span color="grey">{formatDateForDisplay(item.createdAt)}</Span>
                  </Title>
                  <Status status={item.status}>{t(STATUSES[item.status])}</Status>
                </TitleWrapper>
                <Span color="grey">{item.createdBy.fullname}</Span>
              </Block>

              <Block>
                {item.slides.map((slide) => (
                  <Span key={slide.caseSlideReferenceId} color="white">
                    {t('Блок')} {slide.caseBlockReferenceId} <Dot /> {slide.stain?.shortName}
                  </Span>
                ))}
              </Block>

              {item.preparationComment && (
                <Block>
                  <Span color="grey">{t('Комментарий для лаборанта')}</Span>
                  <Span color="white">{item.preparationComment}</Span>
                </Block>
              )}
            </Item>
          ))}
        </StyledPanel>
      )}
    </>
  )
}
